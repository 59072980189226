import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import UIkit from 'uikit';
import { ScreenText } from '@/lang/ScreenText';
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';
import APP_CONST from '@/constants/AppConst';
import ProgressButton from '@/commoncomponents/progressButton/progressButton.vue';
import MultiSelectDropdown from '@/ui-components/multiSelectDropdown/MultiSelectDropdown.vue';
import manageList from '@/store/modules/manageList';
import DropdownList from '@/ui-components/dropdownListBx/DropdownList.vue';
import { ConditionObject, ValidateObject } from '@/Model/model';
@Component({
  components: {
    'progress-button': ProgressButton,
    'datepicker': DatepickerComponent,
    'multi-select-dropdown': MultiSelectDropdown,
    'dropdown': DropdownList,
  },
})
export default class ResetPasswordPopup extends Vue {
  @Prop()
  errorMessage!: boolean;

  @Prop()
  isCountAvailable!: boolean;

  @Prop()
  countOrName!: number|string;

  @Prop({default: ''})
  component!: string;

  @Prop()
  resetTo!: string;

  private objScreenText: ScreenText = new ScreenText();
  public showError: boolean = APP_CONST.FALSE;
  public passLength: any = 0 || '';
  public isErrorPassword: boolean = false;
  public changeClassType: string = '';
  public typeInput: string = APP_CONST.PASSWORD;
  public iscopied: boolean = false;
  public isPasswordValid: ValidateObject = {
    valid: false,
    errors: []
  };
  public apiResponseCode = 0;
  public isValid:boolean = true;


  @Watch('errorMessage')
  onErrorMessage(val: any) {
    if (val === APP_CONST.TRUE) {
      this.isErrorPassword = val;
    }
  }

  setPassword(){
    manageList.setPassword('');
  }

  mounted(){
    this.setPassword();
  }

  destroyed(){
    this.close();
  }

  close() {
    if (UIkit.modal('#reset-password-popup')) {
      UIkit.modal('#reset-password-popup').hide();
    }
    this.iscopied = APP_CONST.FALSE;
    this.typeInput = APP_CONST.PASSWORD;
    manageList.setPassword('');
    this.isPasswordValid = { valid: false, errors: [] };
    this.apiResponseCode = 0;
    this.isValid = true;
    const popupDisplay = <any>document.getElementsByTagName('html');
    popupDisplay[0].classList.remove('uk-modal-page');
  }

  get rules(): Array<ConditionObject> {
    return manageList.getRules;
  }

  get password(): string {
    return manageList.getPassword;
  }

  get changedType(): string {
    return this.typeInput;
  }

  set password(password: string) {
    manageList.setPassword(password);
  }

  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }

  get validatePassword(): ValidateObject {
    const errors = [];
    for (const condition of this.rules) {
      if (this.password && !condition.regex.test(this.password)) {
        errors.push(condition.message);
      }
    }
    if (this.isErrorPassword && this.password) {
      errors.push(APP_CONST.SPECIAL_CHAR_MSG);
    }
    if (errors.length === APP_CONST.ZERO) {
      this.changeClassType = APP_CONST.STYLE_GREEN;
    }
    else {
      (errors.length === APP_CONST.ONE || errors.length === APP_CONST.TWO)
        ? this.changeClassType = APP_CONST.STYLE_YELLOW
        : this.changeClassType = APP_CONST.STYLE_RED;
    }
    const valid = errors.length === 0
      ? true
      : false;
    this.passwordValidity({ valid, errors });
    this.isPasswordValid = { valid, errors };
    return { valid, errors };
  }

  public passwordValidity(object: ValidateObject) {
    manageList.setIsPasswordValid(object);
  }

  public showStrengthString(): string {
    const classType = this.changeClassType;
    const colourVariables = APP_CONST.STRENGTH_COLORS_LIST;
    let strToReturn: string = '';
    switch (classType) {
      case colourVariables[APP_CONST.ZERO]:
        strToReturn = APP_CONST.STRONG;
        break;

      case colourVariables[APP_CONST.ONE]:
        strToReturn = APP_CONST.MEDIUM;
        break;

      case colourVariables[APP_CONST.TWO]:
        strToReturn = APP_CONST.WEAK;
        break;
    }
    return strToReturn;
  }

  public indicatorStrength(errorLength: number, index: number): string {
    const correctedHints: number = APP_CONST.FOUR - errorLength;
    let strClassName = '';
    if (correctedHints === APP_CONST.ZERO && index === APP_CONST.ONE) {
      strClassName = this.changeClassType;
    }
    if ((errorLength === APP_CONST.ONE || errorLength === APP_CONST.TWO) && index < APP_CONST.THREE) {
      strClassName = this.changeClassType;
    }
    else if ((errorLength !== APP_CONST.ONE && errorLength !== APP_CONST.TWO) && index <= correctedHints) {
      strClassName = this.setClassName();
    }
    return strClassName;
  }

  setClassName() {
    return this.changeClassType;
  }

  public checkfortick(errorsNew: any, message: string): boolean {
    return (errorsNew.indexOf(message) === APP_CONST.MINUS_ONE);
  }

  public toggleShowPassword(): void {
    const typeCheck = (typeInput: string) => {
      return typeInput === APP_CONST.PASSWORD
        ? APP_CONST.TEXT
        : APP_CONST.PASSWORD;
    };
    this.typeInput = typeCheck(this.typeInput);

  }

  get isPasswordCopied() {
    return this.iscopied;
  }


  copyPassword(password: string) {
    this.$copyText(password).then((e) => {
      this.iscopied = true;
    });
  }

  resetPassword(){
    this.$emit('resetPassword', this.password);
  }
}